<template>
  <div>
    <div v-if="isLoading" class="d-flex p-5 flex-column align-items-center">
      <b-spinner variant="primary" class="mx-auto" />
    </div>
    <b-card v-else no-body class="coupon-container mx-auto">
      <b-card-body
        v-if="!discountSuccess && coupon && itemsData"
        class="d-flex p-2 flex-column align-items-center"
      >
        <p class=" mt-3 ">
          {{ $t('coupons.use-coupon') }}:
        </p>
        <h1 v-if="coupon.discount" class="mt-1 text-primary font-large-4 text-break text-center">
          -{{ coupon.discount }}<span v-if="coupon.isPercentage">%</span><span v-else>{{ coupon.discountCoin.symbol }}</span>
        </h1>
        <h1 v-else class=" text-primary font-large-2 text-break text-center">
          {{ translateTranslationTable(locale, coupon.name) }}
        </h1>
        <h4 class="mb-3 text-primary">
          {{ translateTranslationTable(locale,item.name) }}
        </h4>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver" class="w-100 mt-1">
          <b-form class="d-flex flex-column" @submit.prevent="handleSubmit(onSubmit)">
            <!-- Price -->
            <validation-provider #default="validationContext" name=" " rules="required">
              <b-form-group label-for="price" :label="$t('coupons.import') + ' (€)'">
                <b-form-input
                  id="price"
                  v-model="form.price"
                  type="number"
                  required
                  rules="required"
                  autocomplete="off"
                  step="0.01"
                  name=" "
                  :placeholder="$t('coupons.import-placeholder')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback class="text-danger">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- comerç code -->
            <validation-provider
              #default="validationContext"
              name=" "
              rules="required|min:5
"
            >
              <b-form-group>
                <label for="code">
                  {{ $t('coupons.code-verification') }}
                  <b-button
                    v-b-tooltip.hover.right
                    :title="$t('coupons.code-note')"
                    variant="link"
                    class="p-0 link-button"
                    @click="showDescription('verification')"
                  >
                    <feather-icon icon="InfoIcon" size="16" />
                  </b-button>
                </label>
                <b-form-input
                  id="code"
                  v-model="form.verificationCode"
                  autocomplete="off"
                  type="password"
                  :placeholder="$t('coupons.code-placeholder')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback class="text-danger">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- User code -->
            <validation-provider
              #default="validationContext"
              name=" "
              rules="required"
            >
              <b-form-group>
                <label for="memberCode">
                  {{ $t('coupons.code-member') }}
                  <b-button
                    v-b-tooltip.hover.right
                    :title="$t('coupons.member-note')"
                    variant="link"
                    class="p-0 link-button"
                    @click="showDescription('member')"
                  >
                    <feather-icon icon="InfoIcon" size="16" />
                  </b-button>
                </label>
                <b-form-input
                  id="memberCode"
                  v-model="form.memberCode"
                  autocomplete="off"
                  :disabled="!member.isStaff"
                  type="number"
                  :placeholder="$t('coupons.code-placeholder')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback class="text-danger">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-alert
              v-if="invalidOrganizationCode"
              show
              variant="danger"
              class="m-0 error-notification"
            >
              {{ $t('form.actions.general-error') }}
            </b-alert>
            <b-alert
              v-if="invalidMemberCode"
              show
              variant="danger"
              class="m-0 error-notification"
            >
              {{ $t('form.actions.general-error') }}
            </b-alert>
            <b-alert
              v-if="generalError"
              show
              variant="danger"
              class="m-0 error-notification"
            >
              {{ $t('form.actions.general-error') }}
            </b-alert>
            <b-button
              type="submit"
              variant="primary"
              class="my-2 button-send mx-auto"
              :disabled="(!form.price || form.price==='' || !form.verificationCode ||form.verification==='') || isSending"
            >
              <span v-if="isSending" class="d-flex align-items-center justify-content-center m-0">
                <b-spinner
                  class="mr-1"
                  small
                  variant="white"
                  label="Loading..."
                />
                {{ $t('form.actions.saving') }}
              </span>
              <span v-else>{{ $t('form.actions.save') }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-body>

      <b-card-body v-if="!isLoading && discountSuccess && discount" class="d-flex p-2 flex-column align-items-center">
        <div class="d-flex flex-column align-items-center justify-content-center mt-3">
          <b-icon-check-circle variant="primary" font-scale="8" class="mb-2 text-center" />

          <h4 class="mt-2 mb-75 text-primary">
            {{ translateTranslationTable(locale,item.name) }}
          </h4>

          <h3 class="text-center  mb-25">
            {{ $t('coupons.success-discount') }}
          </h3>
          <p class="text-center mb-2 text-muted">
            {{ $t('coupons.resume') }}
          </p>
        </div>
        <div class=" mb-3">
          <h6 class="text-center">
            {{ $t('coupons.details') }}
          </h6>
          <div class="d-flex flex-column">
            <small
              class="text-center"
            >{{ $t('coupons.import') }}:
              <span class="text-muted mb-50">{{ discount.import.toFixed(2) }} € </span>
            </small>

            <small
              class="text-center"
            >{{ $t('coupons.percentage') }}

              <span class="text-muted mb-50">{{ +discount.discountPercentage }} % </span></small>
            <small
              class="text-center"
            >{{ $t('coupons.discount') }} :
              <span class="text-muted mb-50">{{ +discount.discount.toFixed(2) }} € </span></small>

            <small
              class="text-center"
            >{{ $t('coupons.new-import') }} :
              <span class="text-muted mb-50">{{ +discount.newImport.toFixed(2) }} €</span></small>
          </div>
        </div>
        <b-button variant="primary mb-2" @click="goToHome">
          {{ $t('coupons.accept') }}
        </b-button>
      </b-card-body>
      <description-input
        v-model="isInputDescriptionModalVisible"
        :title="description.title"
        :description="description.description"
      />
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import DescriptionInput from '@core/components/layouts/apps-layout/DescriptionInput.vue';

export default {
  name: 'CouponForm',
  components: { ValidationObserver, ValidationProvider, DescriptionInput },
  data() {
    return {
      isLoading: false,
      isSending: false,
      bannerStyle: {},
      form: {},
      discount: null,
      required,
      min,
      discountSuccess: false,
      invalidOrganizationCode: false,
      invalidMemberCode: false,
      generalError: false,
      description: {},
      isInputDescriptionModalVisible: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  computed: {
    locale() {
      return this.$store.getters.currentLocale;
    },
    id() {
      return this.$route.params.id;
    },

    type() {
      return 'organizations';
    },
    modelType() {
      return this.$route.query.type;
    },
    itemsData() {
      return this.$store.getters.communitiesOthers[this.modelType];
    },
    item() {
      if (this.itemsData.unpaginated) {
        return this.itemsData.unpaginated[0] || {};
      }
      return this.itemsData || {};
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    couponSlug() {
      return this.$route.query.slug;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    coupon() {
      return this.$store.getters.coupons.unpaginated?.find((item) => item?.key === this.$route.params?.id);
    },
    member() {
      return this.$store.getters.loggedMember;
    },
    hexToDecimalMemberNumber() {
      return parseInt(this.member.key, 16);
    },
  },
  async created() {
    await this.getInitialData();
    await this.fetchData();
    this.form.memberCode = this.hexToDecimalMemberNumber;
  },
  methods: {
    showDescription(item) {
      switch (item) {
        case 'member':
          this.description.title = this.$t('coupons.code-member');
          this.description.description = this.$t('coupons.member-note');
          break;
        default:
          this.description.title = this.$t('coupons.code-verification');
          this.description.description = this.$t('coupons.code-note');
          break;
      }
      this.isInputDescriptionModalVisible = true;
    },
    async getInitialData() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getItems', {
          itemType: 'coupons',
          requestConfig: { modelType: this.modelType, count: 100 },
        });
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
    },

    async fetchData() {
      if (this.items?.key != null) {
        return;
      }
      await this.$store.dispatch('getItems', {
        itemType: 'communities',
        customName: 'communitiesOthers',
        storedKey: this.modelType,
        forceAPICall: true,
        page: 1,
        communitySlug: this.couponSlug || this.communitySlug,
      });
      this.isLoading = false;
    },

    onSubmit() {
      this.isSending = true;
      this.handleCreateItem();
      this.isSending = false;
    },
    goToHome() {
      if (this.member.isStaff) {
        return this.$router.replace({ name: 'payments', params: { communityId: this.couponSlug } });
      }
      const communitySlug = this.collective.parentKey ? this.collective.parentCollective.slug : this.communitySlug;
      return this.$router.replace({ name: 'Resumen', params: { communityId: communitySlug } });
    },
    async handleCreateItem() {
      this.isSending = true;
      this.invalidOrganizationCode = false;
      this.invalidMemberCode = false;
      this.generalError = false;
      try {
        const response = await this.$store.dispatch('createItem', {
          noSet: true,
          communitySlug: this.couponSlug,
          item: {
            itemType: 'useCoupon',
            requestConfig: {
              price: +this.form.price,
              verificationCode: this.form.verificationCode,
              memberCode: this.form.memberCode,
              couponKey: this.id,
              modelType: this.modelType,
            },
          },
        });
        if (response.status === 200) {
          this.discountSuccess = true;
          this.discount = response.data;
        } else if (response.status === 400) {
          this.invalidOrganizationCode = response.data.invalidSpaceVerificationCode;
          this.invalidMemberCode = response.data.invalidMemberCode;
        } else {
          this.generalError = true;
        }
        this.isSending = false;
      } catch (error) {
        this.isSending = false;
      }
    },
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
.discount {
  width: 140px;
  height: 140px;
}
.button-send {
  width: 140px;
}
.coupon-container {
  max-width:700px
}
</style>
